<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex mx-auto">
        <v-spacer></v-spacer>
        <img alt="Success" src="../../assets/Success.png" height="150" />
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex mx-auto">
        <v-spacer></v-spacer>
        <span class="text-h4 green--text text-center">Thank you. Your payment was successful.</span>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex mx-auto">
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            class="white--text"
            elevation="0"
            @click="$router.push('/employer/paymentSchedule/all')"
        >CONTINUE
        </v-btn>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
  name: "SuccessfulTransaction",
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
